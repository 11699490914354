<template>
  <div class="theme-container d-flex df-row">
    <div class="d-flex df-col left-area">
      <h4 class="menu-title">테마</h4>
      <p class="menu-text">사용할 쇼핑몰 솔루션을 선택하세요.</p>
      <p class="menu-sub-text">
        선택한 솔루션용 테마를 먼저 제작해 볼 수 있습니다.
      </p>
      <div class="solution-wrapper d-flex df-col">
        <!-- 메이크샵 -->
        <!--        <div-->
        <!--          class="btn-solution btn-makeshop d-flex df-row cursor"-->
        <!--          @click="selectTheme(2)"-->
        <!--        >-->
        <!--          <img-->
        <!--            :src="require('@/assets/images/introduction/ico_makeshop.svg')"-->
        <!--            class="btn-logo-common"-->
        <!--          />-->
        <!--        </div>-->
        <!-- 마이소호 -->
        <div
          class="btn-solution btn-mysoho d-flex df-row cursor"
          @click="selectTheme(3)"
        >
          <img
            :src="require('@/assets/images/introduction/ico_mysoho.svg')"
            class="btn-logo-common"
          />
        </div>
      </div>
    </div>
    <img
      v-if="width > 1023"
      :src="require('@/assets/images/introduction/bg_theme_right.svg')"
      class="right-area"
    />
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");
export default {
  data() {
    return {
      width: 0
    };
  },
  computed: {
    ...ModuleIntroCommon.mapGetters(["getCurrentMenuInfo"])
  },
  created() {
    this.setCurrentMenuInfo({
      id: 200,
      name: "테마",
      path: "/introduction/theme"
    });
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.width = window.innerWidth;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    //솔루션 선택
    selectTheme(solutionId) {
      this.$router.push("/introduction/theme-list?id=" + solutionId);
    },
    ...ModuleIntroCommon.mapMutations(["setCurrentMenuInfo"])
  }
};
</script>

<style lang="scss">
.theme-container {
  width: 100%;
  background: #fff;
}
.menu-title {
  font-size: 32px;
  line-height: 32px;
  color: #484948;
  font-weight: 500;
}
.menu-text {
  font-size: 14px;
  line-height: 14px;
  color: #484948;
  margin: 16px 0 0 0;
}
.menu-sub-text {
  font-size: 12px;
  line-height: 12px;
  color: #8d8d8d;
  margin: 8px 0 0 0;
}
.solution-wrapper {
  width: 100%;
  padding: 32px 0 0 0;
}
.btn-solution {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  box-sizing: border-box;
}
.btn-makeshop {
  width: 100%;
  border: 1px solid #1f58dd;
}
.btn-mysoho {
  border: 1px solid #00affe;
  //margin: 16px 0 0 0;
}
.btn-logo-common {
  margin: 0 auto;
}
.left-area {
  width: 100%;
  padding: 34px 16px 160px;
  @media screen and (min-width: 1024px) {
    width: 50%;
    padding: 48px 121px 0;
  }
}
.right-area {
  display: none;
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
}
</style>
