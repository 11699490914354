var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "theme-container d-flex df-row"
  }, [_c('div', {
    staticClass: "d-flex df-col left-area"
  }, [_c('h4', {
    staticClass: "menu-title"
  }, [_vm._v("테마")]), _c('p', {
    staticClass: "menu-text"
  }, [_vm._v("사용할 쇼핑몰 솔루션을 선택하세요.")]), _c('p', {
    staticClass: "menu-sub-text"
  }, [_vm._v(" 선택한 솔루션용 테마를 먼저 제작해 볼 수 있습니다. ")]), _c('div', {
    staticClass: "solution-wrapper d-flex df-col"
  }, [_c('div', {
    staticClass: "btn-solution btn-mysoho d-flex df-row cursor",
    on: {
      "click": function click($event) {
        return _vm.selectTheme(3);
      }
    }
  }, [_c('img', {
    staticClass: "btn-logo-common",
    attrs: {
      "src": require('@/assets/images/introduction/ico_mysoho.svg')
    }
  })])])]), _vm.width > 1023 ? _c('img', {
    staticClass: "right-area",
    attrs: {
      "src": require('@/assets/images/introduction/bg_theme_right.svg')
    }
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }